export const menuItems = [
    {
        name: 'Claims/Projects',
        // route.name is used as the unique key in the v-for loop
        route: { name: 'projects' },
        icon: 'shield',
    },
    {
        name: 'Energy',
        route: { name: 'energy' },
        icon: 'energy',
    },
    {
        name: 'Transfer Stations',
        route: { name: 'transfer-stations' },
        icon: 'transfer',
    },
    {
        name: 'Dashboards',
        route: { name: 'reports' },
        icon: 'reports',
    },
    {
        name: 'Admin',
        route: { name: 'admin' },
        icon: 'gear',
    },
];
