<template>
    <!-- HEADER -->
    <header v-if="loggedIn" class="relative flex flex-col items-center gap-y-4 pt-8 xl:pt-0">

        <!-- Profile -->
        <div class="w-full -mt-8 xl:mt-4 -mb-8 xl:-mb-4 p-4 xl:pt-0 xl:px-12 2xl:px-24">
            <div class="hidden lg:flex lg:justify-end w-full">
                <div class="relative">
                    <div class="header-profile-wrap">
                        <RouterLink :to="{ name: 'user-profile-edit' }" class="header-profile">
                            <span class="header-profile_icon-wrap">
                                <PublicIcon icon="profile" width="16" height="16" class="header-profile-icon" />
                            </span>
                            {{ rootStore.user.name }}
                        </RouterLink>
                        <div class="profile-submenu">
                            <div class="uppercase">Account</div>
                            <div class="mt-2">
                                <RouterLink :to="{ name: 'user-profile-edit' }" class="header-profile-edit">
                                    Edit Profile
                                </RouterLink>
                                <a v-if="rootStore.hasRole('ROLE_ADMIN')"
                                   href="/admin"
                                   class="header-profile-edit">EcoClaim Admin</a>
                                <a href="/logout" class="header-profile-logout">Logout</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- logo -->
        <div class="w-full max-w-container -mb-4 lg:mb-0 px-6 xl:px-2 lg:pt-4">
            <div class="flex justify-between w-max">
                <div class="flex mt-0">
                    <RouterLink :to="{ name: 'projects' }" class="header-logo">
                        <img src="../../../images/logo.svg"
                             width="254"
                             height="56"
                             loading="lazy"
                             decoding="async"
                             alt="EcoClaim">
                    </RouterLink>
                </div>
            </div>
            <div>
                <!-- main nav -->
                <div v-if="rootStore.currentCompany"
                     class="hidden lg:flex justify-end space-x-2 lg:space-x-3 w-full -mt-10 pb-2">
                    <RouterLink v-for="item in menuItems"
                                :key="item.route.name"
                                :to="item.route"
                                class="header-nav_item">
                        <PublicIcon :icon="item.icon" width="14" height="14" class="header-icon" />
                        {{ item.name }}
                    </RouterLink>
                </div>

                <!-- Mobile Nav -->

                <div class="lg:hidden mt-4 ml-4">
                    <button type="button" class="button-link button-toggle mt-5" @click="toggleMenu">
                        <PublicIcon icon="menu"
                                    width="24"
                                    height="24"
                                    class="text-eco-blue-secondary-darker stroke-current -mt-1" />
                    </button>
                </div>
                <div v-show="showMobileMenu" ref="mobileMenu" class="header-mobile-open">
                    <button type="button" class="button-toggle mt-5" @click="toggleMenu">
                        <PublicIcon icon="close" width="24" height="24" class="fill-current" />
                    </button>
                    <ul v-if="rootStore.currentCompany" class="header_nav-list-mobile">
                        <li v-for="item in menuItems" :key="item.route.name">
                            <RouterLink :to="item.route" class="header-nav_item">
                                <PublicIcon :icon="item.icon" width="14" height="14" class="header-icon" />
                                {{ item.name }}
                            </RouterLink>
                        </li>
                    </ul>
                    <div class="w-full mt-8 lg:mt-0 pl-12 text-white text-xl">
                        <div class="flex font-light">
                            <RouterLink :to="{ name: 'user-profile-edit' }"
                                        class="header-profile-link header-profile-link-mobile">
                                <span class="header-profile_icon-wrap header-profile_icon-wrap-mobile">
                                    <PublicIcon icon="profile-transparent"
                                                width="32"
                                                height="32"
                                                class="header-profile-icon-mobile" />
                                </span>
                                <div>{{ rootStore.user.name }}</div>
                            </RouterLink>
                            <span class="inline-block mx-3 text-xl text-[#476C95]"> | </span>
                            <a href="/logout" class="header-logout-wrap header-logout-wrap-mobile">Logout</a>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <!-- displays for desktop component located in a different Vue for tablet and mobile -->
        <div class="w-full lg:-mb-4 pt-8 bg-eco-gray-100">
            <CompanySelect class="text-center mx-auto" />
        </div>
    </header>
</template>

<script setup>
import { computed, nextTick, onMounted, ref } from 'vue';
import { useRootStore } from '@/user/stores/root';
import CompanySelect from './company_select.vue';
import { menuItems } from './menu_items';

const rootStore = useRootStore();

const showMobileMenu = ref(false);
const mobileMenu = ref();
const loggedIn = computed(() => rootStore.loggedIn);

onMounted(() => {
    nextTick(() => {
        if (loggedIn.value) {
            window.addEventListener('resize', windowResize);
            mobileMenu.value.addEventListener('click', htmlClick);
        }
    });
});

const toggleMenu = () => {
    showMobileMenu.value = !showMobileMenu.value;

    if (showMobileMenu.value) {
        nextTick(() => {
            mobileMenu.value.focus();
        });
    }
};

const windowResize = () => {
    showMobileMenu.value = false;
};

const htmlClick = (e) => {
    if (e.target.closest('a') || e.target.closest('button')) {
        showMobileMenu.value = false;
    }
};
</script>
